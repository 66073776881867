import React, { useEffect, useState } from "react";
import { Virtual, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./AllVideoSlider.css";
import { AllVideoSliderButton } from "../Services/Services/SliderButton/AllVideoSliderButton";

// Import images

import img2 from "../../../assets/slider/allVideos/img2.jpg";
import img3 from "../../../assets/slider/allVideos/img3.jpg";
import img5 from "../../../assets/slider/allVideos/img5.webp";
import img6 from "../../../assets/slider/allVideos/img6.webp";
import img7 from "../../../assets/slider/allVideos/img17.webp";
import img8 from "../../../assets/slider/allVideos/img8.webp";
import img9 from "../../../assets/slider/allVideos/img9.webp";
import img10 from "../../../assets/slider/allVideos/img10.webp";
import img11 from "../../../assets/slider/allVideos/img11.webp";
import img12 from "../../../assets/slider/allVideos/img12.webp";
import img13 from "../../../assets/slider/allVideos/IMG13.webp";
import img14 from "../../../assets/slider/allVideos/img14.webp";
import img15 from "../../../assets/slider/allVideos/img15.webp";
import img16 from "../../../assets/slider/allVideos/img16.webp";
import spicyWings from "../../../assets/slider/allVideos/spicy_wings.webp";
import jamiacanJark from "../../../assets/slider/allVideos/img17.webp";
import img18 from "../../../assets/slider/allVideos/img18.webp";
import img19 from "../../../assets/slider/allVideos/img19.webp";
import fuska from "../../../assets/slider/allVideos/fuska.webp";
import newYear from "../../../assets/slider/allVideos/new_year.webp";
import VideosModal from "./VideosModal";

const allVideos = [
  {
    des: "🔥💰 New Year...$300 on the line, AND there’s a catch! Fail to finish, and you face the penalty! 🌶️",
    duration: "4:51",
    img: newYear,
    youtubeUrl:
      "https://www.youtube.com/embed/G7F1DKSty18?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "The Spicy Wings Challenge: Who Will Cry First? 🌶️👀 | Sponsored By Cottage Home Care | Friday Fun Day",
    duration: "6:01",
    img: img16,
    youtubeUrl:
      "https://www.youtube.com/embed/MBCTyo9OmCE?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "She ALMOST had it, OMG | Taco Challenge | Friday Fun Day Food Challenge | Cottage Home Care",
    duration: "3:33",
    img: img12,
    youtubeUrl:
      "https://www.youtube.com/embed/12vz6yxxGSU?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "ফুচকা চ্যালেঞ্জ! প্রতিযোগিতার উত্তেজনা তুঙ্গে – দুই প্রতিযোগী, একটি বড় পুরস্কার! এখনই দেখুন!",
    duration: "3:47",
    img: fuska,
    youtubeUrl:
      "https://www.youtube.com/embed/HGRmMKus4rc?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Pizza Pie Challenge l Loser Gets Penalty | Friday Fun Day Food Challenge | Cottage Home Care",
    duration: "4:19",
    img: img8,
    youtubeUrl:
      "https://www.youtube.com/embed/dV6t9P00voE?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },

  {
    des: "Risk or Reward Challenge! | India vs Guyana | $300 On the Line! Sponsored by Cottage Home Care",
    duration: "9:26",
    img: img14,
    youtubeUrl:
      "https://www.youtube.com/embed/ZdJE8sCJFbM?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: " 'Gwaan, give up nuh bredda' | Friday Fun Day Food Challenge |  Brooklyn Institute of Vocational Training ",
    duration: "5:17",
    img: img13,
    youtubeUrl:
      "https://www.youtube.com/embed/2q65v3R-YHA?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Guess the Chocolate Blindfolded & Separation Anxiety | Friday Fun Day Food Challenge | Cottage Home Care",
    duration: "6:02",
    img: img10,
    youtubeUrl:
      "https://www.youtube.com/embed/VCQJliEFxy8?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "OMG - Don't pick THAT cup! | Friday Fun Day Challenge | Cottage Home Care",
    duration: "6:02",
    img: img11,
    youtubeUrl:
      "https://www.youtube.com/embed/mauJBeWAjvM?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Balloon Challenge, Best Out of 3 Rounds | $300 Prize | Friday Fun Day Food Challenge | Cottage Home Care",
    duration: "2:20",
    img: img9,
    youtubeUrl:
      "https://www.youtube.com/embed/RWeQ3cz2YW0?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  // {
  //   des: "Friday Funday Food Chllange By Cottage home Care Services Winners get $500",
  //   duration: "4:58",
  //   img: img2,
  //   youtubeUrl:
  //     "https://www.youtube.com/embed/9t48a9Ckc8o?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  // },
  // {
  //   des: "Match The Bottle Challenge, Friday Funday Food Challenge ",
  //   duration: "3:07",
  //   img: img3,
  //   youtubeUrl:
  //     "https://www.youtube.com/embed/5Ta0VDZmbNY?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  // },
  {
    des: "Who can eat the most? Friday Funday Food Challenge ",
    duration: "2:39",
    img: img6,
    youtubeUrl:
      "https://www.youtube.com/embed/rmL7mpbjDNM?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Behind the scenes - Who can eat the most? Friday Funday Food Challenge",
    duration: "0:35",
    img: img7,
    youtubeUrl:
      "https://www.youtube.com/embed/qWMiLZpoHK8?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Chow Challenge, Friday Funday Food Challenge ",
    duration: "0:56",
    img: img5,
    youtubeUrl:
      "https://www.youtube.com/embed/YJ-9CiyKj0I?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "WHAT JUST HAPPENED! No Way! - Taco Challenge | Sponsored by Cottage Home Care Services | Friday Fun!",
    duration: "5:18",
    img: img15,
    youtubeUrl:
      "https://www.youtube.com/embed/kxZTjxCb5Os?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  
  {
    des: " 'Gwaan, give up nuh bredda' | Friday Fun Day Food Challenge |  Brooklyn Institute of Vocational Training ",
    duration: "5:17",
    img: img13,
    youtubeUrl:
      "https://www.youtube.com/embed/2q65v3R-YHA?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Spicy Wings Challenge | Friday Fun Day Food Challenge | Ft. Singer Vanita aka Poowah | Collaboration",
    duration: "3:51",
    img: spicyWings,
    youtubeUrl:
      "https://www.youtube.com/embed/1AlkjRTfHYY?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "🌶️🔥 Jamaican Jerk Chicken Challenge | Sponsored By Cottage Home Care | Friday Fun Day 🔥🌶️",
    duration: "4:15",
    img:jamiacanJark,
    youtubeUrl:
      "https://www.youtube.com/embed/rrA72WpBAwY?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "🎥 Omardath Maraj & Vanita Willie Judge Trinidadian Doubles Challenge Sponsored by Cottage Home Care🍛",
    duration: "6:18",
    img: img18,
    youtubeUrl:
      "https://www.youtube.com/embed/43qCfiNI5Xs?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
  {
    des: "Pani Puri Challenge Alert! | Sponsored by Cottage Home Care Services | Friday Fun! 🔥",
    duration: "4:29",
    img: img19,
    youtubeUrl:
      "https://www.youtube.com/embed/XzCEv810gI4?rel=0&autoplay=1&clipboard-write=1&encrypted-media=1&gyroscope=1&picture-in-picture=1&web-share=1",
  },
];


export default function App({ bgUrl, setBgUrl }) {

  // 9t48a9Ckc8o
  const backgroungd =
    "https://www.youtube.com/embed/dV6t9P00voE?autoplay=1&mute=1&loop=1&controls=0&enablejsapi=1&iv_load_policy=3&modestbranding=1&playlist=dV6t9P00voE&playsinline=1&rel=0&showinfo=0";

  useEffect(() => {
    setBgUrl(backgroungd);
  }, [backgroungd]);

  const [youtubeUrl, setYoutubeUrl] = useState("");

  const [showModal, setShowModal] = useState(false);

  const openModal = (video) => {
    setShowModal(true);
    setYoutubeUrl(video?.youtubeUrl);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="w-[95%] lg:w-[80%] 2xl:w-[70%] mx-auto text-white mt-[10px] md:mt-[20px] 2xl:mt-[30px]  ">
        <h1 className="text-lg text-start md:text-xl 2xl:text-3xl font-semibold league-spartan">
          {" "}
          Friday Funday Food Challenge{" "}
        </h1>
        <p className="mt-1 md:mt-3 w-[95%] lg:w-[70%] 2xl:w-[50%]  text-sm md:text-base text-start text-[16px] leading-relaxed open-sans font-normal">
          Every Friday, get ready for laughter that'll fill your belly more than
          lunch! <b>Our Funny Friday Food Challenge</b> features the wackiest,
          most unique food competitions. Watch our amazing{" "}
          <b>coordinators, aides, patients, and community members</b> compete in
          a food battle.
        </p>
        <p className="mt-2 2xl:mt-5 text-xl  lg:text-xl 2xl:text-2xl font-semibold text-start league-spartan">
          Watch Now
        </p>
      </div>
      <div
        className="flex allVideo-swiper-page  relative pb-10"
        style={{ height: "88vh", overflow: "hidden" }}
      >
        <Swiper
          modules={[Virtual, Pagination]}
          slidesPerView={3}
          centeredSlides={false}
          spaceBetween={40}
          pagination={{
            type: "fraction",
          }}
          virtual
          grabCursor={true}
          className="w-[90%] md:w-[95%] lg:w-[80%] 2xl:w-[70%]   2xl:h-[330px] h-[280px] lg:h-[250px] all-videos"
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
        >
          {allVideos?.map((video, index) => (
            <SwiperSlide
              onClick={() => openModal(video)}
              className="  video-slide  h-[180px]  lg:h-[160px] 2xl:h-[220px] mt-[35px] md:mt-[45px] 2xl:mt-[45px] "
              key={index}
              virtualIndex={index}
            >
              <div
                className="video-image h-[180px] lg:h-[160px] 2xl:h-[220px] relative"
                style={{
                  backgroundImage: `url(${video?.img})`,
                  backgroundColor: "transparent",
                  backgroundSize: "cover",
                  width: "100%",
                }}
              >
                <p className="bg-[#000000d8] px-2 py-0.5 text-sm text-white absolute top-2 left-2">
                  {video?.duration}
                </p>

                <p className="video-description text-[16px] leading-relaxed open-sans font-normal">{video.des}</p>
              </div>
            </SwiperSlide>
          ))}

          <div className="top-0 right-0 absolute">
            <AllVideoSliderButton />
          </div>
        </Swiper>
      </div>
      {showModal ? (
        <VideosModal youtubeUrl={youtubeUrl} onClose={closeModal} />
      ) : null}
    </div>
  );
}
