import React, { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../Pages/Context/AuthProvider";
import OverlayLoading from "../OverlayLoading/OverlayLoading";
import toast from "react-hot-toast";

const FileUpload = () => {
  const { user } = useContext(AuthContext);
  const [files, setFiles] = useState([]);
  const [cotId, setCotId] = useState(""); // New state for COT-ID
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filePaths, setFilePaths] = useState([]);

  // Handle file selection (multiple files)
  const handleFileChange = (e) => {
    const selectedFiles = [...e.target.files];
    console.log("Selected files:", selectedFiles); // Debug
    setFiles(selectedFiles);
  };

  // Handle COT-ID input change
  const handleCotIdChange = (e) => {
    console.log("COT-ID input:", e.target.value); // Debug
    setCotId(e.target.value);
  };

  // Remove a selected file
  const handleRemoveFile = (fileName) => {
    console.log("Removing file:", fileName); // Debug
    setFiles(files.filter((file) => file.name !== fileName));
  };

  // Handle form submission for file upload (multiple files)
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (files.length === 0) {
      console.warn("No files selected for upload"); // Debug
      setMessage("Please select files first");
      setMessageType("error");
      setLoading(false);
      return;
    }

    console.log("Preparing files for upload:", files); // Debug

    // Prepare FormData with multiple files (no COT-ID here)
    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));
    console.log("FormData content:", [...formData.entries()]); // Debug

    try {
      const response = await axios.post("http://localhost:5000/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Upload response:", response); // Debug

      if (response.data.success) {
        console.log("Uploaded file paths:", response.data.filePaths); // Debug
        setMessage("Files uploaded successfully");
        setMessageType("success");
        setFilePaths(response.data.filePaths); // Array of uploaded file paths
        setShowUserInfo(true);
        setFiles([]); // Clear selected files
      } else {
        console.error("Upload error message:", response.data.message); // Debug
        setMessage(response.data.message || "Error uploading files");
        setMessageType("error");
      }
    } catch (error) {
      console.error("Upload error:", error); // Debug
      setMessage(error.response?.data?.message || "Error uploading files");
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission for user data and upload details, including COT-ID
  const handleSaveDetails = async () => {
    setLoading(true);

    if (!cotId) {
      console.warn("COT-ID is missing"); // Debug
      setMessage("Please enter COT-ID");
      setMessageType("error");
      setLoading(false);
      return;
    }

    const uploadData = {
      filePaths,
      cot_id: cotId, // Include COT-ID in the save details request
      name: user?.displayName,
      email: user?.email,
      uploadTime: new Date().toISOString(),
      status: false,
    };

    console.log("Saving details:", uploadData); // Debug

    try {
      const response = await axios.post("http://localhost:5000/uploadDetails", uploadData);

      console.log("Save details response:", response); // Debug

      if (response.data.success) {
        console.log("Details saved successfully"); // Debug
        setMessage("Details saved successfully");
        setMessageType("success");
        setFilePaths([]);
        setShowUserInfo(false);
        setCotId(""); // Clear COT-ID after saving details
        toast.success("Files uploaded successfully");
      } else {
        console.error("Failed to save details:", response.data.message); // Debug
        setMessage("Failed to save details");
        setMessageType("error");
      }
    } catch (error) {
      console.error("Save details error:", error); // Debug
      setMessage("Error saving details");
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen border-[2px] py-5">
      {loading && <OverlayLoading />}
      <h2 className="text-center text-2xl font-semibold">Upload Direct Deposit</h2>
      {!showUserInfo && (
        <form
          className="border-2 w-[700px] justify-center mx-auto mt-5 border-primary p-8 rounded-md shadow-md"
          onSubmit={handleSubmit}
        >
          <div>
            <label htmlFor="COT-ID" className="block dark:text-gray-100">COT-ID</label>
            <input
              className="my-3 border-[1px] px-2 py-1.5 rounded-md shadow-md border-gray-600 outline-none min-w-[320px]"
              type="text"
              placeholder="COT_ID"
              value={cotId}
              onChange={handleCotIdChange}
              required
            />
          </div>
          <div>
            <input
              className="my-3 border-[1px] px-2 py-1.5 rounded-md shadow-md border-gray-600 min-w-[320px]"
              type="file"
              onChange={handleFileChange}
              multiple
              disabled={showUserInfo}
            />
          </div>
          <div className="my-3">
            <button
              className="px-6 py-1.5 bg-primary text-white rounded-md"
              type="submit"
              disabled={showUserInfo}
            >
              Upload
            </button>
          </div>
          {files.length > 0 && (
            <div className="flex flex-wrap gap-2 mt-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 bg-gray-200 p-1 rounded"
                >
                  <span className="text-xs">{file.name}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(file.name)}
                    className="text-red-500 font-bold cursor-pointer"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}
          {message && (
            <p
              className={`text-sm text-center ${
                messageType === "success" ? "text-green-500" : "text-red-500"
              }`}
            >
              {message}
            </p>
          )}
        </form>
      )}

      {showUserInfo && (
        <div className="border-2 w-[400px] justify-center mx-auto mt-5 border-primary p-4">
          <h3 className="text-center mb-4 text-2xl">Uploaded File Details</h3>
          <div>
            <label className="block">Name:</label>
            <input
              className="w-full border-2 p-2 mb-4"
              type="text"
              value={user?.displayName || ""}
              readOnly
            />
          </div>
          <div>
            <label className="block">Email:</label>
            <input
              className="w-full border-2 p-2 mb-4"
              type="email"
              value={user?.email || ""}
              readOnly
            />
          </div>
          <div className="text-center my-3">
            <button
              className="px-4 py-2 bg-primary text-white"
              onClick={handleSaveDetails}
            >
              Submit Details
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
