import React, { useEffect, useState } from 'react';

// Helper function to show browser notifications and handle clearing localStorage on close/click
const showBrowserNotification = (messageCount, clearStorage, stopRefetch) => {
  if (Notification.permission === 'granted') {
    const notification = new Notification('New User Messages', {
      body: `You have ${messageCount} new message(s).`,
      icon: 'https://i.ibb.co/vmLPfs2/vector-logo.jpg', // Icon for the notification
      requireInteraction: true, // Keeps the notification on the screen until the user interacts with it
    });


    
    // Add notification click handler (when the notification body is clicked)
    notification.onclick = () => {
      const dashboardUrl = "https://cottagehomecare.com/dashboard/users";
    
      if (window.location.href === dashboardUrl) {
        // If the current tab is already showing the dashboard, just bring it to focus
        window.focus(); // Bring the window into focus
        // console.log('Notification clicked! Already on the dashboard. Focusing the window.');
      } else {
        // If the dashboard is not open in the current tab, open it in a new tab
        window.location.href = (dashboardUrl); // Open the URL in a new tab
        // console.log('Notification clicked! Redirecting to the dashboard in a new tab.');
      }
    
      clearStorage(); // Clear the stored message count
      stopRefetch(); // Stop refetching when notification is clicked
    };
    

    // Handle when notification is closed (either by cross icon or closing)
    notification.onclose = () => {
      // console.log('Notification closed! Clearing localStorage for new message count.');
      clearStorage(); // Clear the stored message count
      stopRefetch(); // Stop refetching when notification is closed
    };
  } else {
    console.warn('Notification permission not granted.');
  }
};

const MessageNotification = () => {
  const [chats, setChats] = useState([]); // Store chats in state
  const [notificationMessage, setNotificationMessage] = useState(() => {
    return JSON.parse(localStorage.getItem('lastMessageCount')) || {};
  });

  const totalDisplayMessage = localStorage.getItem("dashBoardMessage"); // Retrieve the display message count from localStorage
  const [refetchActive, setRefetchActive] = useState(true); // State to control refetching

  const clearStorage = () => {
    localStorage.setItem('dashBoardMessage', 0); // Clear displayMessage in localStorage
    setNotificationMessage({}); // Clear notificationMessage state
  };

  const stopRefetch = () => {
    setRefetchActive(false); // Stop refetching
  };

  const startRefetch = () => {
    setRefetchActive(true); // Resume refetching
  };

  const checkForNewMessages = () => {
    // Check for new messages even when refetch is stopped
    fetch("https://cottage-updated-server-v3.vercel.app/chats", {
      method: "GET",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        const newMessageCount = {}; // To store message counts
        let newMessagesExist = false;

        // Check if any new user messages exist
        data?.chats?.forEach((chat) => {
          if (chat?.messages && !chat?.role) { // Detect user messages only
            newMessageCount[chat.userId] = (newMessageCount[chat.userId] || 0) + 1;
            // Check if the new message is really new compared to the last one stored
            const lastCount = notificationMessage[chat.userId] || 0;
            if (newMessageCount[chat.userId] > lastCount) {
              newMessagesExist = true;
            }
          }
        });

        // If new messages are detected after refetching was stopped, restart refetching and show the notification immediately
        if (newMessagesExist) {
          // console.log('New message(s) detected after refetching was stopped. Resuming refetch and showing notification.');
          startRefetch(); // Resume refetching

          // Immediately show notification for the first new message
          const totalNewMessages = Object.keys(newMessageCount).reduce(
            (total, userId) => {
              const lastCount = notificationMessage[userId] || 0;
              const currentCount = newMessageCount[userId];
              return (
                total + (currentCount > lastCount ? currentCount - lastCount : 0)
              );
            },
            0
          );

          if (totalNewMessages > 0) {
            localStorage.setItem("dashBoardMessage", totalNewMessages); // Store new message count in displayMessage
            showBrowserNotification(totalNewMessages, clearStorage, stopRefetch); // Show notification immediately
          }
        }

        // Update localStorage with the current message counts
        localStorage.setItem("lastMessageCount", JSON.stringify(newMessageCount));
        setNotificationMessage(newMessageCount);
      })
      .catch((error) => {
        console.error("Fetch error: ", error);
      });
  };

  const fetchChats = () => {
    fetch("https://cottage-updated-server-v3.vercel.app/chats", {
      method: "GET",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setChats(data.chats);
        const newMessageCount = {}; // To store message counts

        data?.chats?.forEach((chat) => {
          if (chat?.messages && !chat?.role) { // Detect user messages only
            newMessageCount[chat.userId] = (newMessageCount[chat.userId] || 0) + 1;
          }
        });

        const totalNewMessages = Object.keys(newMessageCount).reduce(
          (total, userId) => {
            const lastCount = notificationMessage[userId] || 0;
            const currentCount = newMessageCount[userId];
            return (
              total + (currentCount > lastCount ? currentCount - lastCount : 0)
            );
          },
          0
        );

        if (totalNewMessages > 0) {
          localStorage.setItem("dashBoardMessage", totalNewMessages); // Store new message count in displayMessage
          showBrowserNotification(totalNewMessages, clearStorage, stopRefetch); // Show browser notification and stop refetching when interacted
        }

        // Update the notificationMessage state and localStorage
        localStorage.setItem("lastMessageCount", JSON.stringify(newMessageCount));
        setNotificationMessage(newMessageCount);
      })
      .catch((error) => {
        console.error("Fetch error: ", error);
      });
  };

  useEffect(() => {
    if (!refetchActive) {
      // If refetching is stopped, periodically check for new messages but without refetching
      const checkInterval = setInterval(() => {
        checkForNewMessages(); // Check for new messages every 3 seconds
      }, 3000); // 3000ms = 3 seconds

      // Clear interval when component unmounts or refetching resumes
      return () => clearInterval(checkInterval);
    }

    // Fetch chats immediately when component mounts
    fetchChats();

    // Set interval to fetch data every 3 seconds if refetching is active
    const intervalId = setInterval(() => {
      if (refetchActive) {
        fetchChats();
      }
    }, 3000); // 3000ms = 3 seconds

    // Cleanup function to clear the interval on component unmount or when refetch is stopped
    return () => clearInterval(intervalId);
  }, [refetchActive, notificationMessage]); // Dependencies ensure the effect runs when relevant data changes

  return null; // Component doesn't render anything visible
};

export default MessageNotification;
