import React from "react";
import "./OverHelmade.css";
import Lottie from "lottie-react";
import callReader from "../../../../../src/assets/social-media-lottie/call.json";
import { FaRegCalendar } from "react-icons/fa";

const OverwHelmed = () => {

  

  return (
    <section className="relative bg-white py-5 px-0 md:px-16 flex items-center dark:bg-slate-600 md:w-full w-[95%] mx-auto">
      <div className="md:container mx-auto flex flex-col lg:flex-row items-center gap-8">
        {/* Container for the background and image */}
        <div
         id="shape-bg"
          className="relative lg:w-[650px] md:w-[530px] w-[340px] mx-auto md:mx-0 "
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          {/* Background Blob Shape */}

          <img
            className="absolute w-[400px] md:w-[530px] md:h-[530px] top-2 md:top-4 z-0 rotate-3 md:-left-[10px] -left-[10px] dark:opacity-75"
            src="https://res.cloudinary.com/di3wwp9s0/image/upload/v1732629154/zj5lkpregydcomckn7fc.png"
            alt="bg-shape"
          />

          {/* Masked Image */}
          <div className="relative z-10 masked-div md:w-[550px] md:h-[550px]">
            <img
              src="https://res.cloudinary.com/di3wwp9s0/image/upload/v1733429229/olq1biriqenliholyuyu.webp"
              alt="Cinque Terre"
              className="masked-img"
            />
          </div>

          {/* Dotted Pattern SVG */}
          <svg
            width="250px"
            height="250px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 400"
            aria-labelledby="alt-text"
            role="img"
            className="animated-circle fill-[#1c3461] dark:fill-white absolute right-0 md:right-3 bottom-2 z-10 md:w-[200px] md:h-[200px]  w-[140px] h-[140px]"
          >
            <defs>
              <pattern
                id="dots-pattern"
                patternUnits="userSpaceOnUse"
                width="28"
                height="28"
              >
                <circle cx="10" cy="10" r="3"  />
              </pattern>
            </defs>

            <circle cx="200" cy="200" r="200" fill="url(#dots-pattern)" />
          </svg>
        </div>

        {/* Empty Section for Additional Content */}
        <div>
          <div
            className=" md:pt-5 2xl:pt-8 px-5 md:pl-11 w-full"
            data-aos="fade-left"
            data-aos-duration="2000"
          >
            <h1 className="league-spartan  md:text-3xl text-2xl lg:text-4xl text-primary  font-bold md:pt-5  md:px-0 md:leading-loose dark:text-gray-200">
              When you’re getting the right help,{" "}
              <br className="hidden md:block" /> you live healthier and happier!
            </h1>

            <div className=" text-[16px] leading-relaxed open-sans hyphens-auto md:hyphens-none  w-full font-medium mt-5">
              <h2 className="text-2xl lg:text-4xl font-bold  mb-4 dark:text-gray-100 league-spartan text-primary">
                We Believe...
              </h2>

              <div className="space-y-4 ">
                <div className="flex items-start space-x-3 text-justify md:text-start ">
                  <span className="text-primary dark:text-gray-300 text-xl">&#9733;</span>
                  <p className="dark:text-gray-100">
                    <span className="text-primary font-semibold dark:text-gray-100">
                      Exceptional patient care:
                    </span>{" "}
                    As a home health care company, we prioritize providing the
                    highest level of care to our patients.
                  </p>
                </div>

                <div className="flex items-start text-justify md:text-start space-x-3">
                  <span className="text-primary dark:text-gray-300 text-xl">&#9733;</span>
                  <p className="dark:text-gray-100 ">
                    <span className="text-primary font-semibold dark:text-gray-100">
                      Strong communication & collaboration:
                    </span>{" "}
                    Effective communication and collaboration among all members
                    of the care team – including patients, caregivers,
                    physicians, and other healthcare professionals – is one of
                    the most important aspects of delivering quality home
                    healthcare services.
                  </p>
                </div>

                <div className="flex items-start space-x-3 text-justify md:text-start ">
                  <span className="text-primary dark:text-gray-300 text-xl">&#9733;</span>
                  <p className="dark:text-gray-100">
                    <span className="text-primary font-semibold dark:text-gray-100">
                      Respect for patients' choices and preferences:
                    </span>{" "}
                    Cottage Home Care Services recognizes that each patient is
                    an individual with unique preferences and values, and we
                    respect those preferences in all aspects of care.
                  </p>
                </div>
              </div>
            </div>

            <div className="pt-5 pb-5 md:pb-[120px] font-[Roboto] flex  items-center gap-8 md:gap-0 md:space-y-0 md:space-x-7 flex-wrap">
              <a
                href="tel:+1516-367-2266"
                className="flex items-center px-6 py-3 md:px-5 md:py-4 border border-[#00a6b2] dark:border-[#3A4256] dark:bg-[#3A4256] rounded-full text-white hover:bg-[#00a6b2] duration-500 bg-hov2 league-spartan overflow-hidden xl:text-xl bg-primary"
              >
                {/* <FaPhone className="mr-2 " /> */}
                <span className="h-[20px]  ">
                  <Lottie
                    animationData={callReader}
                    loop={true}
                    className="h-[32px] w-[32px] mr-4 xl:mr-5 "
                    style={{
                      width: "110%",
                      height: "110%",
                      transform: "scale(1.6)",
                      marginTop: "-4px",
                    }}
                  />
                </span>
                Call: (516) 367-2266
              </a>
              <a
                href="#contact-form"
                onClick={(e) => {
                  e.preventDefault();
                  const targetElement = document.getElementById("contact-form");
                  const navbarHeight = 120;
                  if (targetElement) {
                    const offsetTop = targetElement.offsetTop - navbarHeight;
                    window.scrollTo({
                      top: offsetTop,
                      
                    });
                  }
                }}
                className="flex gap-1 -mt-1 px-6 py-3 md:px-8 md:py-4 border border-[#3A4256] rounded-full text-white bg-[#3A4256] duration-500 bg-hov2 league-spartan xl:text-xl cursor-pointer"
              >
                Schedule a Call <FaRegCalendar />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverwHelmed;
