import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form';
import reader from '../../../assets/106680-login-and-sign-up.json';
import Lottie from 'lottie-react';
import { AuthContext } from '../Context/AuthProvider';

const PassWordHandler = () => {

    const [passwordMessage, setPasswordMessage] = useState('')
    const { register, formState: { errors }, handleSubmit, reset } = useForm();


    const {resetPassword} = useContext(AuthContext)

    const passwordHandler = (data)=>{

        // console.log(data)

        resetPassword(data.email)
    
        .then(result => { 
            setPasswordMessage('Password Reset Link has been sent to your email')
            reset();
            // console.log(result)
            
        })
        .catch(error => {
          setPasswordMessage(error.message)
      })

    

        

    }




  return (
    <div className=" grid md:grid-cols-2 -mt-14  mx-auto gap-7  pb-10 items-center dark:bg-slate-600">

            <div className='hidden md:block'>

                <div className='mx-auto '>

                    <Lottie animationData={reader} loop={true}/>

                </div>

            </div>
            <div>
                <div className='flex justify-center  '>
                    <div className="w-full max-w-md px-4 rounded-md  sm:p-8  text-gray-600 shadow-lg bg-[#EBF8F9] dark:bg-slate-800 ">
                        <h2 className="mb-3 text-3xl font-semibold text-center dark:text-gray-100">Reset Your Password</h2>
                       
                       
                        <form onSubmit={handleSubmit(passwordHandler)}  className="space-y-10 ng-untouched ng-pristine ng-valid py-5">
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <label htmlFor="email" className="block text-sm  text-gray-600 dark:text-gray-100">Email address</label>
                                    <input type="email"

                                    onSelect={()=>setPasswordMessage('')}

                                        {...register("email",
                                            { required: "email is required" })}
                                        id="email" placeholder="Type Your Valid Email" className="w-full px-8 py-2  rounded-md bg-white shadow-2xl placeholder:text-gray-500 focus:outline-none dark:bg-gray-400 dark:text-gray-100 dark:placeholder:text-gray-100"  />
                                    {errors.email && <p className='text-red-600'>{errors.email.message}</p>}
                                </div>

                                {
                                    passwordMessage &&
                                    <p className='py-3 text-red-500'>

                                        {
                                            passwordMessage
                                        }

                                    </p>
                                }
                             

                               
                               
                            </div>

                            <button className="w-full px-8 py-3  rounded-md sign-button text-white h-14 bg-gradient-to-r bg-primary hover:bg-secondary">Submit</button>
                         
                        </form>
                        
                      
                    </div>

                </div>
            </div>

        </div>
  )
}

export default PassWordHandler
