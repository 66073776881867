import React, { useEffect, useState } from "react";
import EasierLife from "../EasierLife/EasierLife";
import Quality from "../Quality/Quality";
import WeAre from "../WeAre/WeAre";
import WhyChoose from "../WhyChoose/WhyChoose";
import UpdateTestimonial from "./UpdateTestimonial";
import CustomCarousel from "../../../../Components/CustomCarousel/CustomCarousel";
import NewsLetter from "../../../NewsLetter/NewsLetter";
import PageComponent from "../../../PageComponent/PageComponent";
import WebLoading from "../../../WebLoading/WebLoading";
import "./Home.css";
import MapComponent from "./MapComponent";
import OverwHelmed from "./OverwHelmed";
import MedicaidApply from "./MedicaidApply";
import CottageVideoSlider from "./CottageVideoSlider"


const Home = () => {
  const webLoading = sessionStorage.getItem("webLoading");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      sessionStorage.setItem("webLoading", true);
      setIsLoading(false);
    }, 1000);
  }, [sessionStorage.getItem("webLoading")]);

  return (
    <div>
      <PageComponent
        title="Cottage Home Care Services"
        description="Cottage Home Care Services offers compassionate senior care in New York, handling the most challenging cases with expertise and dedication. Discover our CDPAP, HHA, and personalized care services."
        keywords="Cottage, Cottage Home Care, CDPAP services, Home Health Aide NYC, Senior home care New York, PCA care, CDPAP coordinator, Home care Suffolk County, personalized care New York, NHTD program, senior care Bronx, care cottage, CDPAP agency near me"
      />

      {isLoading && !webLoading ? (
        <>
          <WebLoading />
        </>
      ) : (
        <>
        
          <div className="dark:bg-slate-600">
            <CustomCarousel />
           
{/* 
            <div className="hidden md:block">
              <Welcome></Welcome>
            </div> */}
            {/* <NewSwiper/> */}
            {/* <div className="block md:hidden">
              <NewWelcome></NewWelcome>
            </div> */}

            {/* new testimonial section  start */}
          
            <UpdateTestimonial />

            {/* new testimonial  section end  */}

            {/* <HelpSection></HelpSection> */}
            <OverwHelmed/>
            <WeAre></WeAre>
            <Quality></Quality>
            <WhyChoose></WhyChoose>
            {/* <Benefits></Benefits> */}
            <MedicaidApply/>
            <CottageVideoSlider/>
            <MapComponent />
            <EasierLife></EasierLife>
            <NewsLetter />

           {/* <Chat></Chat>    */}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
